import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ENVIRONMENT } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  constructor(
    private gtmService: GoogleTagManagerService,
  ) {}

  /**
   * Initializes tracking codes
   **/
  public initialize() {
    if (ENVIRONMENT.production) {
      this.gtmService.addGtmToDom();
    }
  }

  /**
   * Tracks user actions across the app
   *
   * @gmtTag An object of Google Tag Manager properties. Pairs of key and value
   **/
  public track(gtmTag: { [key: string]: string }) {
    if (ENVIRONMENT.production) {
      this.gtmService.pushTag(gtmTag);
    }
  }
}
